import React from "react";
import Layout from "../../../../components/Layout/layout";
import TaxiVsBusBlog from "../../../../components/Blog-Detail/whyTaxi/index";
import SEO from "../../../../components/SEO/seo";
const TaxiVsBus = () => {
	return (
		<Layout>
			<SEO
				title="Why do people prefer to take a taxi than a bus?"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="When it comes to traveling option, then people often neglect the bus and opt for the taxi  because of its comfortness and availability at any time and anywhere."
			/>
			<TaxiVsBusBlog />
		</Layout>
	);
};

export default TaxiVsBus;
